const names = {
    HOME: 'home',
    ABOUT_US: 'aboutUs',
    SERVICES: 'services',
    COMMERCE: 'commerce',
    OUR_CLIENTS: 'ourClients',
    IMPLEMENTED_PROJECTS: 'implementedProjects',
    CONTACTS: 'contacts',
  };
  
  export default Object.freeze(names);
  