import { createWebHistory, createRouter } from 'vue-router';
import routeNames from './routeNames';
import Services from "../components/Services/Services.vue";
import Service from "../components/Service/Service.vue";
import Home from "../components/Home.vue";
import Contacts from "../components/Contacts/Contacts.vue";
import ESProjects from '../components/ESProjects/ESProjects.vue';

const routes = [
	{
		path: "/",
		name: routeNames.HOME,
		component: Home,
	},
	{
		path: "/paslaugos",
		name: routeNames.SERVICES,
		component: Services,
		props: true,
	},
	{
		path: "/projektavimas",
		name: "design",
		component: Services,
	},
	{
		path: "/:serviceType",
		name: "singleServices",
		component: Services,
		props: true,
	},
	{
		path: "/:serviceType/:serviceName",
		name: "service",
		component: Service,
		props: true,
	},
	{
		path: "/naujienos",
		name: "news",
		component: Service,
	},
	{
		path: "/apie-mus",
		name: "about-us",
		component: Service,
	},
	{
		path: "/prekyba",
		name: "commerce",
		component: Services,
	},
	{
		path: "/nuoma",
		name: "rental",
		component: Services,
	},
	{
		path: "/musu-klientai",
		name: "our-clients",
		component: Service,
	},
	{
		path: "/igyvendinti-projektai",
		name: "implemented-projects",
		component: Service,
	},
	{
		path: "/europos-sajungos-projektai",
		name: "es-projects",
		component: ESProjects,
	},
	{
		path: "/kontaktai",
		name: "contacts",
		component: Contacts,
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		document.body.scrollTop = 0;
	}
});

// createApp(app).use(router).mount('#app')

export default router;