<template>
    <div class='service'>
        <div class='serviceNameSmall'>
            {{serviceData.name}}
        </div>
        <div class='servicePhotoDiv'>
            <img :src="require('../../assets/' + serviceData.image)" :alt="serviceData.alt" class='serviceImage'/>
        </div>
        <div class='serviceText' >
            <div class='serviceNameBig'>
                {{serviceData.name}}
            </div>
            <div v-if="serviceData.buttonNumber === 1">
                <div class='serviceInfo'>
                    <newLineText :description="serviceData.description"></newLineText>
                </div>
                <div class='serviceButton'>
                    <primaryButton 
                        :goTo="serviceData.goTo" 
                        :buttonText="serviceData.buttonText" 
                        :serviceName="serviceData.serviceName" 
                        :serviceType="serviceData.serviceType" 
                        :webpage="serviceData.webpage">
                    </primaryButton>
                </div>
            </div>
            <div v-if="serviceData.buttonNumber > 1">
                <div v-for="index in serviceData.buttonNumber" :key="index" class="multipleButtons">
                    <div class='serviceInfo'>
                        <newLineText :description="[serviceData.description[index - 1]]"></newLineText>
                    </div>
                    <div class='serviceButton'>
                        <primaryButton 
                            :goTo="serviceData.goTo" 
                            :buttonText="serviceData.buttonText" 
                            :serviceName="serviceData.serviceName" 
                            :serviceType="serviceData.serviceType" 
                            :webpage="serviceData.webpage">
                        </primaryButton>
                    </div>
                </div>
            </div>
            <div v-if="serviceData.buttonNumber === 0">
                <div class='serviceInfo'>
                    <newLineText :description="serviceData.description"></newLineText>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import i18n from '../../i18n';
import NewLineText from "./NewLineText.vue";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default ({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Services",
  components: {
    newLineText: NewLineText,
    primaryButton: PrimaryButton
  },
  props: {
    serviceEn: Object,
    serviceLt: Object,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    serviceData () {
        if (i18n.global.locale.value === 'lt') {
            return this.serviceLt;
        } else {
            return this.serviceEn;
        }
    }
  }
});
</script>
<style>
.service{
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: black;
    border:none;
    text-transform: uppercase;
}

.service + .service{
    border-top: 2px solid var(--background-black);
    border-image: linear-gradient(to right, rgba(0,0,0,0) 5%,var(--text-on-white) 5%, var(--text-on-white) 95%, rgba(0,0,0,0) 95%); /* to top - at 50% transparent */
    border-image-slice: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.servicePhotoDiv{
    padding-left: 64px;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serviceImage{
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--background-black);
    text-decoration: none;
}

.serviceText{
    padding-right: 64px;
    text-align: right;
    width: 70%;
    padding-left: 16px;
}

.serviceNameSmall{
    display: none;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 8px;
    text-transform: uppercase;
    color: black;
    margin: 12px;
}

.serviceNameBig{
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 8px;
    text-transform: uppercase;
    color: black;
    margin: 12px;
}

.serviceInfo{
    display: flex;
    flex-direction: column;
    /* font-size: 16px; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 8px;
    padding-bottom: 16px;
}

.multipleButtons {
    padding-bottom: 16px;
}

@media only screen and (max-width: 1024px) {
    .service{
        max-width: 750px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .service + .service{
        border-top: 2px solid var(--background-black);
        border-image: linear-gradient(to right, rgba(0,0,0,0) 2%,var(--text-on-white) 2%, var(--text-on-white) 98%, rgba(0,0,0,0) 98%); /* to top - at 50% transparent */
        border-image-slice: 1;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .servicePhotoDiv{
        padding-left: 48px;
    }
    .serviceText{
        padding-right: 32px;
        padding-left: 12px;
    }
    .serviceNameSmall{
        display: none;
        font-size: 1.15rem;
        margin: 6px 12px 6px 0;
    }
    .serviceNameBig{
        font-size: 1.15rem;
        margin: 6px 12px 6px 0;
    }
    .serviceInfo{
        font-size: 1rem;
        line-height: 8px;
        padding-bottom: 16px;
    }
}

@media only screen and (max-width: 800px) {
    .service{
        max-width: 600px;
    }

    .servicePhotoDiv{
        padding-left: 32px;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .serviceText{
        padding-right: 32px;
        text-align: right;
        width: 55%;
        padding-left: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .service{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .serviceNameBig{
        display: none;
    }

    .serviceNameSmall{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.15rem;
        margin: 6px 32px;
    }

    .servicePhotoDiv{
        padding: 12px;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .serviceText{
        padding: 12px;
        text-align: right;
        width: 70%;
    }
    .serviceButton a{
        padding: 0;
    }

}
</style>
