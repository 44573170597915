import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import i18n from './i18n'

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .component("font-awesome-icon", faFacebook)
    .mount("#app");
