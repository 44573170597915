<template>
    <div>
        <Hero />
        <div class="serviceBody">
            <div class="serviceContainer" v-if="service">
                <h1 class="serviceTitle">{{service.title}}</h1>
                <div class='serviceContent'>
                    <div v-for="description in service.description" :key="description">
                        <p>{{description}}</p>
                    </div>
                    <div v-for="additionalInfo in service.additionalInfo" :key="additionalInfo">
                        <div v-if="additionalInfo.type === 'text'">
                            <p><strong>{{additionalInfo.title}}</strong>{{additionalInfo.info}}</p>
                            <p>{{additionalInfo.description}}</p>
                        </div>
                        <div v-if="additionalInfo.type === 'list'">
                            <p><strong>{{additionalInfo.title}}</strong></p>
                            <ul class="serviceList">
                                <li v-for="info in additionalInfo.info" :key="info">{{info}}</li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="service.additionalService">
                        <p><strong>{{service.additionalService.title}}</strong></p>
                        <ul class="serviceList">
                            <li v-for="info in service.additionalService.info" :key="info">{{info}}</li>
                        </ul>
                    </div>
                </div>
                <div class='buttonBody'>
                    <primaryButton goTo="home" serviceName="" serviceType="" buttonText="atgal" webpage=""></primaryButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '../../i18n';
import serviceDataEn from "./dataEn.json";
import serviceDataLt from "./dataLt.json";
import Hero from '../../components/Hero/Hero.vue';
import PrimaryButton from "../../components/Buttons/PrimaryButton.vue";

export default({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Service",
  props: {
    serviceName: String,
  },
  components: {
    Hero,
    primaryButton: PrimaryButton
  },
  data() {
    return {
      serviceLt: {},
      serviceEn: {},
    };
  },
  created() {
    if (this.serviceName) {
        var serviceIndex;
        if (i18n.global.locale.value === 'lt') {
            serviceIndex = serviceDataLt.findIndex( service => service.name === this.serviceName);
        }
        else {
            serviceIndex = serviceDataEn.findIndex( service => service.name === this.serviceName);
        }
        this.serviceLt = serviceDataLt[serviceIndex];
        this.serviceEn = serviceDataEn[serviceIndex];
    } else {
        const pathElements = this.$route.path.split("/");
        var serviceIndexFromPath;
        if (i18n.global.locale.value === 'lt') {
            serviceIndexFromPath = serviceDataLt.findIndex( service => service.name === pathElements[2]);
        }
        else {
            serviceIndexFromPath = serviceDataEn.findIndex( service => service.name === pathElements[2]);
        }
        this.serviceLt = serviceDataLt.find[serviceIndexFromPath];
        this.serviceEn = serviceDataEn.find[serviceIndexFromPath];
    }
  },
computed: {
    service () {
        if (i18n.global.locale.value === 'lt') {
            return this.serviceLt;
        }
        else {
            return this.serviceEn;
        }
    }
  }
});
</script>

<style scoped>
.serviceBody  {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.serviceContainer {
    border-radius: 8px;
    background-color: white;
    width: 100%;
    max-width: 1000px;
    color: black;
    padding: 16px 0;
    width: 100%;
}
.serviceContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 48px;
}
.serviceTitle {
    text-transform: uppercase;
    font-size: 1.5rem;
}
.serviceList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.serviceList li{
    text-align: start;
}
p {
    text-align: start;
}

.buttonBody {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 32px;
}

@media only screen and (max-width: 1024px) {
    .serviceContainer{
        max-width: 750px;
        padding: 16px 0;
    }

    .serviceContent {
        padding: 0 32px;
    }
}

@media only screen and (max-width: 800px) {
    .serviceContainer{
        max-width: 600px;
        margin: 0px 32px;
    }
    .serviceTitle {
        font-size: 1.5rem;
        padding: 0 32px;
    }
}

@media only screen and (max-width: 600px) {
    .serviceBody  {
        margin: 40px 0 0 0;
    }
    .serviceContainer {
        border-radius: 0;
    }
}

@media only screen and (max-width: 480px) {
}
</style>
