export default {
  "core": {
    "paslaugos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paslaugos"])},
    "es_projektai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES Projektai"])},
    "kontaktai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "projektavimas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektavimas"])},
    "prekyba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekyba"])},
    "nuoma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuoma"])},
    "placiau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačiau"])},
    "kainynas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kainynas"])},
    "atgal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgal"])},
    "european_union_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europos Sąjungos Projektai"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vardas"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. Paštas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žinutė"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siųsti"])}
  },
  "contacts": {
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktorius"])},
    "audio_system_engineer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garso sistemų inžinierius"])},
    "design_and_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekyba ir Projektavimas"])},
    "technical_production_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Techninis prodiusavimas"])}
  }
}