<template>
    <div>
        <Hero />
        <div class="projectsBody">
            <div class="projectsContainer">
                <div class='containerTitle'>{{ $t('core.european_union_projects') }}</div>
                <div class='projectsContent'>
                    <div class="projectInfo">
                        <h3>{{projectData.header}}</h3>
                        <div v-for="description in projectData.descriptions" :key="description">
                            <p>{{description}}</p>
                        </div>
                        <div v-for="additionalDescription in projectData.additionalDescriptions" :key="additionalDescription">
                            <p>{{additionalDescription}}</p>
                        </div>
                        <div 
                            v-for="additionalInfo in projectData.additionalInfo" :key="additionalInfo"
                            class="additionalInfo"
                        >
                            <p><strong>{{additionalInfo.title}}</strong> {{additionalInfo.text}}</p>
                        </div>
                    </div>
                    <div class='photoContainer'>
                        <img src="../../assets/ESFIVP-I-1.jpg" :alt="projectData.alt"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '../../i18n';
import projectDataLt from './dataLt.json';
import projectDataEn from './dataEn.json';
import Hero from '../../components/Hero/Hero.vue';

export default({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ESProject",
  props: {
    serviceName: String,
  },
  components: {
    Hero,
  },
  data() {
    return {
    };
  },
  computed: {
    projectData () {
            if (i18n.global.locale.value === 'lt') {
                return projectDataLt;
            } else {
                return projectDataEn;
            }
        }
    }
});
</script>

<style scoped>
.projectsBody  {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.projectsContainer{
    border-radius: 8px;
    background-color: white;
    width: 100%;
    max-width: 1000px;
    color: black;
    padding: 16px 0;
}

.containerTitle{
    font-size: 2rem ;                   /* 32 px */
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
}
.projectsContent {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
}
.projectsTitle {
    text-transform: uppercase;
    font-size: 1.5rem;
}
.projectInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.projectInfo p {
   text-align: start;
   margin: 12px 0;
}
.photoContainer {
    max-width: 400px;
}
.photoContainer img{
    width: 100%;
    text-decoration: none;
}
.additionalInfo p{
   margin: 12px 0;
}

@media only screen and (max-width: 1024px) {
    .projectsContainer{
        max-width: 750px;
        padding: 16px 0;
    }

    .projectsContent {
        padding: 0 32px;
    }
}

@media only screen and (max-width: 800px) {
    .projectsContainer {
        max-width: 600px;
        margin: 0px 32px;
    }
    .projectsTitle {
        font-size: 1.5rem;
        padding: 0 32px;
    }
}

@media only screen and (max-width: 600px) {
    .projectsBody  {
        margin: 40px 0 0 0;
    }
    .projectsContainer {
        border-radius: 0;
    }
}

</style>
