<template>
  <Hero />
  <div class="servicesContainer">
    <div v-for="service in filteredServices" :key="service.serviceName" class='services'>
        <div class='containerTitle'>{{ $t(`core.${service.name}`) }}</div>
        <serviceLine :serviceDataName="service.serviceDataName"></serviceLine>
    </div>
  </div>
  
</template>


<script>
import { defineComponent } from "vue";
import ServiceLine from "./ServiceLine.vue";
import Hero from "../Hero/Hero.vue";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Services",
  components: {
    serviceLine: ServiceLine,
    Hero
  },
  props: {
    serviceType: String,
  },
  data() {
    return {
      services: [
        {serviceName: "Projektavimas", serviceDataName: "designData", name: "projektavimas"},
        {serviceName: "Prekyba", serviceDataName: "commerceData", name: "prekyba"},
        {serviceName: "Nuoma", serviceDataName: "rentalData", name: "nuoma"}
      ],
      filteredServices: []
    };
  },
  mounted() {
    if (this.serviceType === 'all') {
        this.filteredServices = this.services;
    }
    else if (this.serviceType) {
        this.services.find( service => {
          if (service.name === this.serviceType) {
            this.filteredServices.push(service);
          }
    });
    } else {
        const pathElements = this.$route.path.split("/");
        if (pathElements[1] === 'paslaugos') {
          this.filteredServices = this.services;
        } else {
          this.services.find( service => {
            if ( service.name === pathElements[1]) {
              this.filteredServices.push(service);
            }
          });
        }
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
template {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
 }
.servicesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.services{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.containerTitle{
    font-size: 2rem ;                   /* 32 px */
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
    .servicesContainer{
      max-width: 750px;
    }
    .containerTitle {
      padding-top: 20px;
      padding-bottom: 20px;
    }
}
@media only screen and (max-width: 800px) {
  .servicesContainer{
    max-width: 650px;
    padding: 0 32px;
  }
  .services{
    max-width: 650px;
  }
}

@media only screen and (max-width: 600px) {
  .servicesContainer{
    padding: 0;
  }
}
</style>
