<template>
    <div class="contactContainerBody">
        <Hero />
        <div class="contactBody">
            <div class="contactContainer">
                <div class='containerTitle'>{{ $t('core.kontaktai') }}</div>
                <div class='contactContent'>
                    <div class="contactInfo">
                        <div v-for="person in contactData" :key="person.id" class="contactDiv">
                            <div class="contactPeople">
                                <h4 class="contactName info">{{person.name}}</h4>
                                <p v-for="occupation in person.occupation" :key="occupation" class="info">
                                    {{ $t(`contacts.${occupation}`) }}
                                </p>
                            </div>
                            <div class="contactPeopleInfo">
                                <h4 class="info"><a :href="'mailto:' + person.email">{{person.email}}</a></h4>
                                <p class="info"><a :href="'tel:' + person.phoneNumber">{{person.phoneNumber}}</a></p>
                            </div>
                            
                        </div>
                    </div>
                    <div class="contactMessageContainer">
                        <div class="write-message-body">
                            <label>{{ $t('core.name') }}</label>
                            <input type="text" name="firstName" v-model="firstName" />
                            <label>{{ $t('core.email') }}</label>
                            <input type="email" name="email" v-model="email" />
                            <label>{{ $t('core.title') }}</label>
                            <input type="text" name="lastName" v-model="lastName" />
                            <label>{{ $t('core.message') }}</label>
                            <textarea
                                rows="2"
                                class="message"
                                name="message"
                                v-model="message"
                            ></textarea>
                            <div class="bottomForm">
                            <button @click="sendEmail()" class="button">{{ $t('core.submit') }}</button>
                            <p v-if="showMessage" :class="messageStatus">{{ warningMessage }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import contactData from "./data.json";
import Hero from '../../components/Hero/Hero.vue';

export default({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Contacts",
  props: {
    serviceName: String,
  },
  components: {
    Hero,
  },
  data() {
    return {
      contactData: contactData,
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      messageStatus: "",
      showMessage: false,
      loading: false,
    };
  },
    methods: {
    sendEmail() {
      this.loading = true;
    //   emailjs
    //     .send(
    //       "service_55vbpua",
    //       "template_t6b92u6",
    //       {
    //         firstName: this.firstName,
    //         lastName: this.lastName,
    //         email: this.email,
    //         message: this.message,
    //       },
    //       "UtB1SJJQ-Wd9dpz6b"
    //     )
    //     .then(() => {
    //       this.showMessage = true;
    //       this.warningMessage = "Message has been sent";
    //       this.messageStatus = "successful";
    //     })
    //     .catch((error) => {
    //       this.showMessage = true;
    //       this.warningMessage = "Something went wrong, please try again";
    //       this.messageStatus = "error";
    //       console.log("FAILED...", error);
    //     })
    //     .finally(() => {
    //       this.loading = false;

    //       // Reset form field
    //       this.firstName = "";
    //       this.lastName = "";
    //       this.email = "";
    //       this.message = "";

    //       setTimeout(function () {
    //         this.showMessage = false;
    //         this.warningMessage = "";
    //       }, 5000);
    //     });
    },
  },
});
</script>

<style scoped>
.contactBody  {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.contactContainer{
    border-radius: 8px;
    background-color: white;
    width: 100%;
    max-width: 1000px;
    color: black;
    padding: 16px 0;
}

.containerTitle{
    font-size: 2rem ;                   /* 32 px */
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
}
.contactContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    padding: 0 48px;
}
.contactTitle {
    text-transform: uppercase;
    font-size: 1.5rem;
}

.contactInfo {
    display: flex;
    flex-direction: column;
}

.contactDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    min-height: 170px;
}

.contactPeople {
    display: flex;
    flex-direction: column;
    align-items:  flex-end;
    justify-content: center;
    padding-right: 18px;
    border-right: 2px solid var( --accent-color);
    width: 200px;
}

.contactPeopleInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 18px;
    text-align: start;
}
.contactName {
    text-transform: uppercase;
}
.contactPeople p {
    text-align: end;
    text-transform: uppercase;
    font-size: smaller;
}

.info {
    margin: 5px 0;
}

.contactMessageContainer {
    width: 100%;
    padding: 5 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.write-message-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.write-message-body h2 {
    margin-top: 0;
}
.write-message-body label {
  text-transform: uppercase;
  margin: 6px 8px;
}
.write-message-body input,
textarea {
  border: none;
  min-width: 70%;
  background: rgb(214, 214, 214);
  border-radius: 6px;
  color: #000000;
  line-height: 1.2;
  height: 16px;
  padding: 8px 16px 8px 16px;
  text-align: end;
}

textarea {
  height: 88px;
}

.bottomForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottomForm p {
    margin: 0 16px;
}

.bottomForm p.successful {
  padding: 0 16px;
  background: rgb(120, 236, 132);
  border-radius: 6px;
  color: #000000;
  height: 36px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.bottomForm p.error {
  padding: 0 16px;
  background: rgb(237, 69, 69);
  border-radius: 6px;
  color: #000000;
  height: 36px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.write-message-body button {
    font-size: 0.95rem;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    margin: 16px 0;
    padding: 0 21px;
    background: rgb(214, 214, 214);
    border-radius: 6px;
    color: #000000;
    line-height: 1.2;
    height: 36px;
}

.write-message-body button:hover {
  cursor: pointer;
  background: rgb(189, 189, 189);
  /* color: #fff; */
}

@media only screen and (max-width: 1024px) {
  .contactContainer {
    max-width: 750px;
  }
  .contactContent {
    padding: 0 32px;
  }
}

@media only screen and (max-width: 800px) {
  .contactContainer {
      max-width: 600px;
      margin: 0px 32px;
    }
  .contactTitle {
      font-size: 1.5rem;
      padding: 0 32px;
  }
  .contactContent {
      flex-direction: column;
      justify-content: center;
      align-content: center;
  }
  .contactDiv {
      justify-content: center;
      align-content: center;
  }

  .contactPeople {
    width: 50%;
  }

  .contactPeopleInfo {
    width: 50%;
  }

  .contactMessageContainer {
    padding-top: 12px;
      justify-content: center;
      align-content: center;
  }
  .write-message-body {
    width: 100%;
    align-items: flex-start;
  }

  .write-message-body input,
  textarea {
    width: 90%;
    text-align: start;
  }
}

@media only screen and (max-width: 600px) {
  .contactBody  {
      margin: 40px 0 0 0;
  }
  .contactContainer {
      border-radius: 0;
  }
  
  .contactMessageContainer {
    padding-top: 18px;
  }
}
@media only screen and (max-width: 550px) {
  
  .contactContainerBody  {
     width: 100%;
  }
  .contactDiv {
    flex-direction: column;
    align-content: flex-start;
    border-bottom: 2px solid var( --accent-color);
  }
  .contactPeople {
    align-items:  flex-start;
    border-right: 0px;
    width: 100%;
  }
  .contactPeopleInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 8px 0 0 0;
      text-align: start;
      font-size: 0.9rem;
  }
  .contactPeople p {
    text-align: start;
  }
  .info {
      text-align: start;
  }

  .info a{
      padding: 0;
  }

}
</style>
