<template>
  <div id="app">
    <nav class='header'>
      <div class='headerContainer'>
        <div class="logoContainer">
          <router-link to="/" class="logoLink"><img :src="logo" alt="no noise logo" class='logo' /></router-link>
        </div>
        <div class="links" >
          <div class="linksContainer">
            <!-- <router-link to="/naujienos">Naujienos</router-link> -->
            <!-- <router-link to="/apie-mus">Apie Mus</router-link> -->
            <router-link to="/paslaugos">{{ $t('core.paslaugos') }}</router-link>
            <!-- <router-link to="/prekyba">Prekyba</router-link> -->
            <!-- <router-link to="/musu-klientai">Mūsų Klientai</router-link> -->
            <!-- <router-link to="/igyvendinti-projektai">Įgyvendinti Projektai</router-link> -->
            <router-link to="/europos-sajungos-projektai">{{ $t('core.es_projektai') }}</router-link>
            <router-link to="/kontaktai">{{ $t('core.kontaktai') }}</router-link>
          </div>
          <div class="langSwitcher">
            <div v-if="lang === 'lt'" class="lastLink"><p @click="changeLanguage('en')" style="cursor: pointer;">ENG</p></div>
            <div v-if="lang === 'en'" class="lastLink"><p @click="changeLanguage('lt')" style="cursor: pointer;">LT</p></div>
          </div>
        </div>
      </div>
    </nav>
    <router-view :key="$route.fullPath"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import logo from './assets/logo.png';
import Footer from './components/Footer/Footer.vue';

  export default {
    name: 'app',
    components: {
      Footer
    },
    data() {
      return {
        logo: logo,
        lang: this.$i18n.locale
      };
    },
    methods: {
      changeLanguage(language) {
        this.$i18n.locale = language;
        this.lang = this.$i18n.locale;
      }
    }
  };
</script>
<style>

#app {
  font-family:'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-display: swap;
}

html, body {
  box-sizing: inherit;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
  background-color: black;
}

.headerContainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header{
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  /* height: min-content; */
}

a {
  transition: all 0.15s ease;
  color: rgb(24, 24, 24);
  width: auto;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  font-weight: bold;
  padding: 10px;
}
.logoContainer a{
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
    width: 64px;
    height: 64px;
    padding: 6px 0;
}

.logoLink {
  padding: 0;
}

.links {
  margin-left: 5%;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.lastLink {
  font-weight: 600;
  padding-right: 0 0 0 10px;
  border: none;
}

.languageOption {
  padding-left: 2px;
  font-size: 0.9rem;
  border-radius: 6px;
}

:root{
  --accent-color: #DBB124;
  --accent-color-darker: #dfcc8b;

  /* LIGHT MODE */
  --text-on-white-light: black;
  --background-white-light: white;
  --text-on-black-light: white;
  --background-black-light: black;
  --button-gb-light: black;
  --button-text-light: white;
  /* DARK MODE */

  --text-on-white-dark: white;
  --background-white-dark: black;
  --text-on-black-dark: black;
  --background-black-dark: white;
  --button-bg-dark: white;
  --button-text-dark: black;
}


:root{
  --text-on-white: var(--text-on-white-light);
  --background-white: var(--background-white-light);
  --text-on-black: var(--text-on-black-light);
  --background-black: var(--background-black-light);
  --button-bg:  var(--button-bg-light);
  --button-text: var(--button-text-light);
}

.container{
  width: 100%;
  max-width: 1140px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 1024px) {
  .headerContainer {
    max-width: 750px;
  }
}

@media only screen and (max-width: 800px) {
  .headerContainer {
    max-width: 480px;
  }

  .links {
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .header{
    max-width: 600px;
    width: 100%;
  }
  .headerContainer {
    max-width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .links {
    flex-direction: column;
    margin: 0;
    padding: 12px 0;
  }
  .langSwitcher {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px;
  }
  .lastLink:hover {
	cursor: pointer;
  }
  .linksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
  }
  .linksContainer a{
    padding: 8px;
  }
}
</style>
