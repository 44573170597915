export default {
  "core": {
    "paslaugos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "es_projektai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES Projects"])},
    "kontaktai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "projektavimas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
    "prekyba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "nuoma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental"])},
    "placiau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
    "kainynas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list"])},
    "atgal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "european_union_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Union Projects"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "contacts": {
    "ceo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
    "audio_system_engineer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio System Engineer"])},
    "design_and_sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design And Sales"])},
    "technical_production_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Production Manager"])}
  }
}